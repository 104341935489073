var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",class:[_vm.selected && 'selected'],attrs:{"height":"60vh","headers":_vm.headers,"loading":_vm.loading,"items":_vm.tokenContracts,"page":_vm.pagination.page,"footer-props":{
                        'items-per-page-options':[5,10,20],
                        itemsPerPageText:''
                      },"server-items-length":_vm.pagination.totalItems,"items-per-page":_vm.pagination.rowsPerPage,"loading-text":"Loading... Please wait","item-key":"name"},on:{"click:row":_vm.goToTokenContract,"update:items-per-page":_vm.itemsPerPageChange,"update:page":_vm.pageChange},scopedSlots:_vm._u([{key:"item.avatarUrl",fn:function(ref){
                      var item = ref.item;
return [(item.avatarUrl)?_c('v-img',{staticStyle:{"width":"30px","height":"30px","object-fit":"contain"},attrs:{"src":item.avatarUrl}}):_vm._e()]}},{key:"item.address",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.address))]),_c('v-btn',{staticClass:"ml-4",attrs:{"icon":"","target":"_blank","href":item.scanUrl},on:{"click":function (event){event.stopPropagation()}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("link")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }