<template>
  <div>
    <v-container>
      <base-header>
        <div slot="page-header">
          <h2 class="font-weight-black primary--text">Token contracts</h2>
        </div>
        <div slot="page-search">
          <div class="d-flex justify-end">
            <v-btn depressed dark color="success"
                   :loading="loading"
                   class="ml-4"
                   @click="triggerAutoDiscovery"
            >
              RUN AUTO DISCOVERY
            </v-btn>
            <v-btn fab
                   dark
                   x-small
                   color="primary"
                   class="ml-4"
                   @click="goToCreateTokenContract"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>

          </div>

        </div>
      </base-header>
    </v-container>
    <token-contracts/>
  </div>

</template>

<script>
import BaseHeader from "../../components/Base/BaseHeader.vue";
import BackButton from "@/components/Common/BackButton";
import TokenContracts from "@/views/tables/TokenContracts";
import axios from "axios";

export default {
  components: {
    BackButton,
    BaseHeader,
    TokenContracts,
  },
  computed: {},
  methods: {
    goToCreateTokenContract(){
      this.$router.push({path: '/token-contracts/new'})
    },
    triggerAutoDiscovery() {
      this.loading = true;
      let url = process.env.VUE_APP_BASE_API_URL + '/admin/nft-contract/auto-discovery';
      axios.post(url).then(resp => {
        this.$toast.success('Autodiscovery triggered!')
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  data() {
    return {
      loading: false
    }
  }
}
</script>

<style scoped>

</style>
