<template>
  <v-container>
    <v-data-table v-model="selected"
                  height="60vh"
                  :headers="headers"
                  :loading="loading"
                  @click:row="goToTokenContract"
                  :items="tokenContracts"
                  :page="pagination.page"
                  :footer-props="{
                          'items-per-page-options':[5,10,20],
                          itemsPerPageText:''
                        }"
                  @update:items-per-page="itemsPerPageChange"
                  @update:page="pageChange"
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.rowsPerPage"
                  loading-text="Loading... Please wait"
                  item-key="name"
                  :class="[selected && 'selected']"
                  class="elevation-1">
      <template v-slot:item.avatarUrl="{ item }">
        <v-img :src="item.avatarUrl" v-if="item.avatarUrl" style="width:30px;height:30px;object-fit: contain;"/>
      </template>

      <template v-slot:item.address="{ item }">
        <span>{{ item.address }}</span>
        <v-btn
            class="ml-4"
            icon
            target="_blank"
            @click="event=>{event.stopPropagation()}"
            :href="item.scanUrl">
          <v-icon size="16">link</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: 'token-contracts',
  methods: {
    pageChange(newPage) {
      this.pagination.page = newPage
      this.fetchTokenContracts()
    },
    itemsPerPageChange(newPerPage) {
      this.pagination.rowsPerPage = newPerPage
      this.fetchTokenContracts()
    },
    goToTokenContract(tokenContract) {
      this.$router.push({name: 'TokenContract', params: {tokenContractId: tokenContract.id}})
    },
    fetchTokenContracts() {
      this.loading = true;
      let url = process.env.VUE_APP_BASE_API_URL + '/admin/nft-contract';
      axios.get(url, {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.rowsPerPage,
          sort: 'created,desc'
        }
      }).then(resp => {
        this.tokenContracts = resp.data.content.map(contract => {
          let scanUrl = `https://etherscan.io/token/${contract.address}`;
          switch (contract.chain) {
            case 'BNB':
              scanUrl = `https://bscscan.com/token/${contract.address}`;
              break;
            case 'MATIC':
              scanUrl = `https://polygonscan.com/token/${contract.address}`;
              break;
            case 'KLAY':
              scanUrl = `https://scope.klaytn.com/search/tokens-nft-account?key=${contract.address}`;
              break;
          }
          return {
            ...contract,
            scanUrl
          }
        })
        this.pagination.totalItems = resp.data.totalElements
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  created() {
    this.fetchTokenContracts()
  },
  data() {
    return {
      pagination: {
        descending: true,
        sortBy: 'created',
        rowsPerPage: 10,
        page: 1,
        totalItems: 0,
      },
      search: null,
      loading: false,
      tokenContracts: [],
      selected: [],
      headers: [{
        text: '',
        align: 'start',
        sortable: false,
        value: 'avatarUrl',
        width: 40
      }, {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
      }, {
        text: 'Address',
        sortable: false,
        value: 'address'
      }, {
        text: 'Chain',
        sortable: false,
        value: 'chain'
      }, {
        text: 'Symbol',
        sortable: false,
        value: 'symbol'
      },
      ],
    }
  },
};
</script>
<style scoped>
.selected {
  cursor: pointer;
}
</style>
